import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntegrarColetaERPRequest } from 'src/app/classes/coletas/integrar-coleta-erprequest';
import { ListarColetasPorPlanejamentoResponse } from 'src/app/classes/coletas/listar-coletas-por-planejamento-response';
import { ListarQuantidadeProdutosColetadosPorPlanejamentoResponse } from 'src/app/classes/coletas/listar-quantidade-produtos-coletados-por-planejamento-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ColetasService {

  constructor(
    private http:HttpClient
  ) { }


  public listarColetasPorPlanejamento(empresaID: number, filialID: number, seqPvl: number, seqPvs: number, linhaID: number, sublinhaID: number): Observable<ListarColetasPorPlanejamentoResponse>{
    return this.http.get(`${environment.baseUrlCSPDotNet}/api/planejamentos/${empresaID}/${filialID}/${seqPvl}/${seqPvs}/${linhaID}/${sublinhaID}/coletas`).pipe(
      map((result:ListarColetasPorPlanejamentoResponse) =>{
        return result;
      })
    );
  }


  public listarQuantidadeProdutosColetados(empresaID: number, filialID: number, seqPvl: number, seqPvs: number, linhaID: number, sublinhaID: number): Observable<ListarQuantidadeProdutosColetadosPorPlanejamentoResponse>{
    return this.http.get(`${environment.baseUrlCSPDotNet}/api/planejamentos/${empresaID}/${filialID}/${seqPvl}/${seqPvs}/${linhaID}/${sublinhaID}/listarQuantidadeProdutosColetados`).pipe(
      map((result: ListarQuantidadeProdutosColetadosPorPlanejamentoResponse) =>{
        return result;
      })
    )
}

  public integrarColetasDePlanejamentoERP(integrarColetaERPRequest: IntegrarColetaERPRequest){
    return this.http.post(`${environment.baseUrlCSPDotNet}/api/coletas/integrarERP`, integrarColetaERPRequest).pipe(
      map(x => {
        return x;
      })
    )
  }
}

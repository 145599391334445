export class ListarQuantidadeProdutosColetadosPorPlanejamentoResponse {
  ProdutosColetados: Array<ProdutosColetadosDto>;
}

export class ProdutosColetadosDto {
  Produto: ProdutoDto;
  public QuantidadeColetada: number;
  public QuantidadeTransbordada: number;
  public QuantidadeATransbordar: number;
}

export class ProdutoDto {
  public ProdutoID: number;
  public Descricao: string;
}

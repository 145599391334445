import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { WinAuthInterceptor } from './interceptors/win-auth-interceptor';
import { AuthenticationService } from './services/auth/authentication.service';
import { AferirPesoComponent } from './components/planejamentos/aferir-peso/aferir-peso.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { TransbordarComponent } from './components/planejamentos/transbordar/transbordar.component';
import { ExibirErroComponent } from './components/validacoes/exibir-erro/exibir-erro.component';
// eslint-disable-next-line max-len
import { DetalharCabecalhoPlanejamentoComponent } from './components/planejamentos/detalhar-cabecalho-planejamento/detalhar-cabecalho-planejamento.component';
import { AferirPesoTransbordoComponent } from './components/transbordos/aferir-peso-transbordo/aferir-peso-transbordo.component';
registerLocaleData(ptBr, 'pt');

@NgModule({
  // eslint-disable-next-line max-len
  declarations: [AppComponent, AferirPesoComponent, AferirPesoTransbordoComponent, TransbordarComponent, ExibirErroComponent, DetalharCabecalhoPlanejamentoComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule],
  providers: [
    { provide: AuthenticationService, useClass: AuthenticationService },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WinAuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export class IntegrarColetasPorTransbordoERPRequest {
  TransbordoID: string;
  PlanejamentosLinhaTransbordo: Array<PlanejamentoTransbordoDto>;
  PlanejamentosSublinha: Array<PlanejamentoDto>;
}


export class PlanejamentoTransbordoDto {
  EmpresaID: number;
  FilialID: number;
  SeqPvl: number;
  Linha: LinhaDto;
  NumeroTicket: number;
  PesoAferido: number;
  DataPrevistaSaida: Date;
  DataPrevistaChegada: Date;
  Veiculo: VeiculoDto;
  PesoTotalColetas: number;
}

export class LinhaDto {
  LinhaID: number;
  Descricao: string;
}

export class PlanejamentoDto {
  EmpresaID: number;
  FilialID: number;
  SeqPvl: number;
  LinhaID: number;
  SeqPvs: number;
  SublinhaID: number;
}

export class VeiculoDto {
  Placa: string;
}

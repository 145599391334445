import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IntegrarColetaERPRequest } from 'src/app/classes/coletas/integrar-coleta-erprequest';
import { ListarColetasPorPlanejamentoResponse } from 'src/app/classes/coletas/listar-coletas-por-planejamento-response';
import { PlanejamentoAguardandoAfericaoPeso } from 'src/app/classes/planejamentos/planejamento-aguardando-afericao-peso';
import { ColetasService } from 'src/app/services/coletas/coletas.service';
import { TicketService } from 'src/app/services/ticket.service';

@Component({
  selector: 'app-aferir-peso',
  templateUrl: './aferir-peso.component.html',
  styleUrls: ['./aferir-peso.component.scss'],
})
export class AferirPesoComponent implements OnInit {
  @Input() planejamentosAguardandoAfericaoPeso: Array<PlanejamentoAguardandoAfericaoPeso>;

  declare integrarColetasERPRequest: Array<IntegrarColetaERPRequest>;
  declare listarColetasPorPlanejamentoResponse: Array<ListarColetasPorPlanejamentoResponse>;
  declare pesoTotalColetado: number;
  declare pesoTotalAferido: number;


  constructor(
    private modalController: ModalController,
    private coletaService: ColetasService,
    private ticketService: TicketService,
  ) {
    this.integrarColetasERPRequest = new Array<IntegrarColetaERPRequest>();
    this.listarColetasPorPlanejamentoResponse = Array<ListarColetasPorPlanejamentoResponse>();
    this.pesoTotalColetado = 0;
    this.pesoTotalAferido = 0;

  }

  ngOnInit() {
    const reducer = (previousValue: any, currentValue: any) => previousValue + currentValue;
    this.pesoTotalColetado = this.planejamentosAguardandoAfericaoPeso.reduce(reducer).PesoTotalColetas;

    this.planejamentosAguardandoAfericaoPeso.forEach(planejamentoAguardandoAfericaoPeso => {
      this.coletaService.listarColetasPorPlanejamento(planejamentoAguardandoAfericaoPeso.EmpresaID, planejamentoAguardandoAfericaoPeso.FilialID, planejamentoAguardandoAfericaoPeso.SeqPvl,
        planejamentoAguardandoAfericaoPeso.SeqPvs, planejamentoAguardandoAfericaoPeso.Linha.LinhaID, planejamentoAguardandoAfericaoPeso.Sublinha.SublinhaID).subscribe(data => {
          this.listarColetasPorPlanejamentoResponse.push(data);
        });
    });
  }


  buscarPesoTicket(event: any, planejamento: PlanejamentoAguardandoAfericaoPeso) {
    var numeroTicket = event.target.value;

    this.ticketService.detalharTicket(planejamento.EmpresaID, planejamento.FilialID, numeroTicket, planejamento.Veiculo.Placa, planejamento.DataPrevistaSaida)
      .subscribe(data => {
        planejamento.PesoAferido = data.PesoAferido;
        this.pesoTotalAferido = data.PesoAferido;


        var integracaoColetaErpRequestExistente = this.integrarColetasERPRequest.find(x => x.EmpresaID == planejamento.EmpresaID &&
          x.FilialID == planejamento.FilialID &&
          x.SeqPvl == planejamento.SeqPvl &&
          x.SeqPvs == planejamento.SeqPvs &&
          x.LinhaID == planejamento.Linha.LinhaID);

        if (integracaoColetaErpRequestExistente == undefined) {
          var integrarColetaERPRequest = new IntegrarColetaERPRequest();
          integrarColetaERPRequest.PesoAferido = data.PesoAferido;
          integrarColetaERPRequest.NumeroTicket = numeroTicket;
          integrarColetaERPRequest.EmpresaID = planejamento.EmpresaID;
          integrarColetaERPRequest.FilialID = planejamento.FilialID;
          integrarColetaERPRequest.SeqPvl = planejamento.SeqPvl;
          integrarColetaERPRequest.SeqPvs = planejamento.SeqPvs;
          integrarColetaERPRequest.LinhaID = planejamento.Linha.LinhaID;
          integrarColetaERPRequest.SublinhaID = planejamento.Sublinha.SublinhaID;
          integrarColetaERPRequest.DataSaida = planejamento.DataSaida;
          integrarColetaERPRequest.DataRetorno = planejamento.DataRetorno;
          integrarColetaERPRequest.QuilometragemInicial = planejamento.QuilometragemInicial;
          integrarColetaERPRequest.QuilometragemFinal = planejamento.QuilometragemFinal;

          this.integrarColetasERPRequest.push(integrarColetaERPRequest);
        } else {
          integracaoColetaErpRequestExistente.NumeroTicket = numeroTicket;
          integracaoColetaErpRequestExistente.PesoAferido = data.PesoAferido;
        }
      }, err => {
        console.log(err);
      });
  }

  salvarAfericaoPeso() {
    this.integrarColetasERPRequest.forEach(integrar => {
      this.coletaService.integrarColetasDePlanejamentoERP(integrar).subscribe(() => {
        this.modalController.dismiss(true);
      }, err => {
        console.error(err);
      });
    });
  }
}

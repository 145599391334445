export class RealizarPlanejamentoTransbordoRequest {
  PlanejamentoLinha: PlanejamentoLinha;
  PlanejamentoSublinha: PlanejamentoSublinha;
  ItensTransbordar: Array<ItensTransbordar>;
  static PlanejamentoLinha: any;
}

export class PlanejamentoLinha {
  EmpresaID: number;
  FilialID: number;
  SeqPvl: number;
  LinhaID: number;
  PlacaVeiculo: string;
  MotoristaID: number;
  NomeMotorista: string;
}

export class PlanejamentoSublinha {
  EmpresaID: number;
  FilialID: number;
  SeqPvl: number;
  SeqPvs: number;
  LinhaID: number;
  SublinhaID: number;
}

export class ItensTransbordar {
  ProdutoID: number;
  Quantidade: number;
}

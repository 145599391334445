import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


declare loggedIn:boolean;
  constructor(
    private http: HttpClient
  ) {     
    this.loggedIn = false;
  }


  getUser(): Observable<string> {
    let serviceUrl: string = `${environment.baseUrlCSPDotNet}/api/usuarios`;
    return this.http.get(serviceUrl, {responseType: 'text'}).pipe(
      map((rslt: string) => {
        this.loggedIn = true;
        return rslt;
      }, err => {
        this.loggedIn = false;
      })
    );
  }
}

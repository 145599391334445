import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlanejamentoAguardandoAfericaoPeso } from 'src/app/classes/planejamentos/planejamento-aguardando-afericao-peso';
import { PlanejamentoAguardandoTransbordo } from 'src/app/classes/planejamentos/planejamento-aguardando-transbordo';
import { PlanejamentoLinhaDisponivelParaTransbordo } from 'src/app/classes/planejamentos/planejamento-linha-disponivel-para-transbordo';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlanejamentoService {

  constructor(private http: HttpClient) { }


  public listarPlanejamentoAguardandoAfericaoDePeso(): Observable<Array<PlanejamentoAguardandoAfericaoPeso>> {
    return this.http.get(`${environment.baseUrlCSPDotNet}/api/planejamentos/aguardandoAfericaoPeso`).pipe(
      map((result: Array<PlanejamentoAguardandoAfericaoPeso>) => result)
    );
  }

  public listarPlanejamentoAguardandoTransbordo(): Observable<Array<PlanejamentoAguardandoTransbordo>> {
    return this.http.get(`${environment.baseUrlCSPDotNet}/api/planejamentos/aguardandoTransbordo`).pipe(
      map((result: Array<PlanejamentoAguardandoTransbordo>) => result)
    );
  }

  public listarPlanejamentosLinhasDisponiveisTransbordo(): Observable<Array<PlanejamentoLinhaDisponivelParaTransbordo>> {
    return this.http.get(`${environment.baseUrlCSPDotNet}/api/planejamentos/transbordos/linhas`).pipe(
      map((result: Array<PlanejamentoLinhaDisponivelParaTransbordo>) => result)
    );
  }
}

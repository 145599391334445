import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/auth/authentication.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  public ambienteConectado: string;

  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.getUser().subscribe(data => {
    });
  }
  ngOnInit(): void {
    this.ambienteConectado = environment.baseUrlCSPDotNet;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { PlanejamentoAguardandoAfericaoPeso } from 'src/app/classes/planejamentos/planejamento-aguardando-afericao-peso';

@Component({
  selector: 'app-detalhar-cabecalho-planejamento',
  templateUrl: './detalhar-cabecalho-planejamento.component.html',
  styleUrls: ['./detalhar-cabecalho-planejamento.component.scss'],
})
export class DetalharCabecalhoPlanejamentoComponent implements OnInit {
  @Input() planejamento: PlanejamentoAguardandoAfericaoPeso;
  @Input() pesoBruto: number;
  constructor() { }

  ngOnInit() {
    console.log(this.planejamento);
  }
}

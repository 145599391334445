import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-exibir-erro',
  templateUrl: './exibir-erro.component.html',
  styleUrls: ['./exibir-erro.component.scss'],
})
export class ExibirErroComponent implements OnInit {

  @Input() errorMsg: string;
  @Input() displayError: boolean;

  constructor() { }

  ngOnInit() { }

}

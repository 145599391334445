export class IntegrarColetaERPRequest {
    public PesoBruto: number;
    public PesoAferido: number;
    public NumeroTicket: number;
    public EmpresaID: number;
    public FilialID: number;
    public SeqPvl: number;
    public SeqPvs: number;
    public LinhaID: number;
    public SublinhaID: number;
    public DataSaida: Date;
    public DataRetorno: Date;
    public QuilometragemInicial: number;
    public QuilometragemFinal: number;
}
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardGuard } from './services/auth/auth-guard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    canLoad: [AuthGuardGuard],
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((module) => module.HomePageModule),
    canActivate: [AuthGuardGuard]

  },
  {
    path: 'acesso-nao-autorizado',
    loadChildren: () => import('./pages/acesso-nao-autorizado/acesso-nao-autorizado.module').then(m => m.AcessoNaoAutorizadoPageModule)
  },
  {
    path: 'aguardando-afericao-peso',
    loadChildren: () => import('./pages/planejamentos/aguardando-afericao-peso/aguardando-afericao-peso.module').then(m => m.AguardandoAfericaoPesoPageModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'aguardando-transbordo',
    loadChildren: () => import('./pages/planejamentos/aguardando-transbordo/aguardando-transbordo.module').then(m => m.AguardandoTransbordoPageModule),
    canActivate: [AuthGuardGuard]
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

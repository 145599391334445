import { Injectable, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class ExibirErroService {
  declare public formGroup:FormGroup;

  constructor() { }
  public validar(): boolean{
    this.validateAllFormFields(this.formGroup)
    return this.formGroup.status == "VALID"
  }

  isFieldValid(field: string, fieldIdentification) {
    if(fieldIdentification != null)
      field = field + fieldIdentification;

    return !this.formGroup.get(field).valid && this.formGroup.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field, null),
      'has-feedback': this.isFieldValid(field, null)
    };
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
}

import { Injectable } from '@angular/core';
import {  HttpRequest, HttpHandler,  HttpInterceptor, HttpEvent} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
 
 
@Injectable()
export class WinAuthInterceptor implements HttpInterceptor{
  constructor(){}
  
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}
 
  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
        withCredentials: true
      });
    return next.handle(req);
  }
}
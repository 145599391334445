import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntegrarColetasPorTransbordoERPRequest } from 'src/app/classes/transbordo/integrar-coletas-por-transbordo-erprequest';
// eslint-disable-next-line max-len
import { listarTransbordosAguardandoAfericaoDePesoResponse } from 'src/app/classes/transbordo/listar-transbordos-aguardando-afericao-peso/listar-transbordos-aguardando-afericao-peso-response';
import { RealizarPlanejamentoTransbordoRequest } from 'src/app/classes/transbordo/realizar-planejamento-transbordo-request';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransbordoService {

  constructor(private http: HttpClient) { }

  public realizarPlanejamentoTransbordo(realizarPlanejamentoTransbordoRequest: RealizarPlanejamentoTransbordoRequest){
    return this.http.post(`${environment.baseUrlCSPDotNet}/api/transbordos`, realizarPlanejamentoTransbordoRequest);
  }


  public integrarColetasDeTransbordoERP(integrarColetasPorTransbordoERPRequest: IntegrarColetasPorTransbordoERPRequest){
    return this.http.post(`${environment.baseUrlCSPDotNet}/api/transbordos/integrarERP`, integrarColetasPorTransbordoERPRequest).pipe(
      map(x => x)
    );
  }

  public listarTransbordosAguardandoAfericaoDePeso(): Observable<listarTransbordosAguardandoAfericaoDePesoResponse>{
    return this.http.get(`${environment.baseUrlCSPDotNet}/api/transbordos/aguardandoAfericaoPeso`).pipe(
      map((result: listarTransbordosAguardandoAfericaoDePesoResponse) => result)
    );
  }
}

/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ListarColetasPorPlanejamentoResponse } from 'src/app/classes/coletas/listar-coletas-por-planejamento-response';
import { IntegrarColetasPorTransbordoERPRequest, LinhaDto, PlanejamentoDto, PlanejamentoTransbordoDto, VeiculoDto } from 'src/app/classes/transbordo/integrar-coletas-por-transbordo-erprequest';
import { TransbordoAguardandoAfericaoPeso } from 'src/app/classes/transbordo/transbordo-aguardando-afericao-peso';
import { ColetasService } from 'src/app/services/coletas/coletas.service';
import { TicketService } from 'src/app/services/ticket.service';
import { TransbordoService } from 'src/app/services/transbordos/transbordo.service';

@Component({
  selector: 'app-aferir-peso-transbordo',
  templateUrl: './aferir-peso-transbordo.component.html',
  styleUrls: ['./aferir-peso-transbordo.component.scss'],
})
export class AferirPesoTransbordoComponent implements OnInit {
  @Input() transbordoAguardandoAfericaoPeso: TransbordoAguardandoAfericaoPeso;

  declare integrarColetasPorTransbordoERPRequest: IntegrarColetasPorTransbordoERPRequest;
  declare listarColetasPorPlanejamentoResponse: Array<ListarColetasPorPlanejamentoResponse>;
  declare pesoTotalColetado: number;
  declare pesoTotalAferido: number;

  constructor(private ticketService: TicketService,
    private coletaService: ColetasService,
    private transbordoService: TransbordoService,
    private modalController: ModalController) {
    this.integrarColetasPorTransbordoERPRequest = new IntegrarColetasPorTransbordoERPRequest();
    this.listarColetasPorPlanejamentoResponse = Array<ListarColetasPorPlanejamentoResponse>();

    this.integrarColetasPorTransbordoERPRequest.PlanejamentosLinhaTransbordo = new Array<PlanejamentoTransbordoDto>();
    this.integrarColetasPorTransbordoERPRequest.PlanejamentosSublinha = new Array<PlanejamentoDto>();
    this.pesoTotalColetado = 0;
    this.pesoTotalAferido = 0;
  }

  ngOnInit() {
    this.integrarColetasPorTransbordoERPRequest.TransbordoID = this.transbordoAguardandoAfericaoPeso.TransbordoID;
    this.transbordoAguardandoAfericaoPeso.TransbordoPlanejamentos.forEach(transbordoPlanejamentoLinha => {
      if (this.integrarColetasPorTransbordoERPRequest
        .PlanejamentosLinhaTransbordo
        .find(x => x.SeqPvl === transbordoPlanejamentoLinha.PlanejamentoLinha.SeqPvl
          && x.Linha.LinhaID === transbordoPlanejamentoLinha.PlanejamentoLinha.Linha.LinhaID
          && x.EmpresaID === transbordoPlanejamentoLinha.PlanejamentoLinha.EmpresaID
          && x.FilialID === transbordoPlanejamentoLinha.PlanejamentoLinha.FilialID) === undefined) {
        var planejamentoTransbordoDto: PlanejamentoTransbordoDto = new PlanejamentoTransbordoDto();
        planejamentoTransbordoDto.EmpresaID = transbordoPlanejamentoLinha.PlanejamentoLinha.EmpresaID;
        planejamentoTransbordoDto.FilialID = transbordoPlanejamentoLinha.PlanejamentoLinha.FilialID;
        planejamentoTransbordoDto.Linha = new LinhaDto();
        planejamentoTransbordoDto.Linha.LinhaID = transbordoPlanejamentoLinha.PlanejamentoLinha.Linha.LinhaID;
        planejamentoTransbordoDto.Linha.Descricao = transbordoPlanejamentoLinha.PlanejamentoLinha.Linha.Descricao
        planejamentoTransbordoDto.SeqPvl = transbordoPlanejamentoLinha.PlanejamentoLinha.SeqPvl;
        planejamentoTransbordoDto.DataPrevistaSaida = transbordoPlanejamentoLinha.PlanejamentoLinha.DataPrevistaSaida;
        planejamentoTransbordoDto.Veiculo = new VeiculoDto();
        planejamentoTransbordoDto.Veiculo.Placa = transbordoPlanejamentoLinha.PlanejamentoLinha.Veiculo.Placa;
        planejamentoTransbordoDto.PesoTotalColetas = transbordoPlanejamentoLinha.PlanejamentoLinha.PesoTotalColetas;
        planejamentoTransbordoDto.DataPrevistaChegada = transbordoPlanejamentoLinha.PlanejamentoLinha.DataPrevistaChegada;

        this.integrarColetasPorTransbordoERPRequest.PlanejamentosLinhaTransbordo.push(planejamentoTransbordoDto);

      };
    });

    this.transbordoAguardandoAfericaoPeso.TransbordoPlanejamentos.forEach(transbordoPlanejamentoSublinha => {

      if(this.integrarColetasPorTransbordoERPRequest.PlanejamentosSublinha
        .find(x=>x.EmpresaID === transbordoPlanejamentoSublinha.PlanejamentoSublinha.EmpresaID
              && x.FilialID === transbordoPlanejamentoSublinha.PlanejamentoSublinha.FilialID
              && x.LinhaID === transbordoPlanejamentoSublinha.PlanejamentoSublinha.Linha.LinhaID
              && x.SeqPvl === transbordoPlanejamentoSublinha.PlanejamentoSublinha.SeqPvl
              && x.SeqPvs === transbordoPlanejamentoSublinha.PlanejamentoSublinha.SeqPvs) === undefined) {

      const planejamentoDto: PlanejamentoDto = new PlanejamentoDto();
      planejamentoDto.EmpresaID = transbordoPlanejamentoSublinha.PlanejamentoSublinha.EmpresaID;
      planejamentoDto.FilialID = transbordoPlanejamentoSublinha.PlanejamentoSublinha.FilialID;
      planejamentoDto.LinhaID = transbordoPlanejamentoSublinha.PlanejamentoSublinha.Linha.LinhaID;
      planejamentoDto.SeqPvl = transbordoPlanejamentoSublinha.PlanejamentoSublinha.SeqPvl;
      planejamentoDto.SeqPvs = transbordoPlanejamentoSublinha.PlanejamentoSublinha.SeqPvs;
      planejamentoDto.SublinhaID = transbordoPlanejamentoSublinha.PlanejamentoSublinha.SeqPvs;

      this.integrarColetasPorTransbordoERPRequest.PlanejamentosSublinha.push(planejamentoDto);
      this.coletaService.listarColetasPorPlanejamento(transbordoPlanejamentoSublinha.PlanejamentoSublinha.EmpresaID, transbordoPlanejamentoSublinha.PlanejamentoSublinha.FilialID, transbordoPlanejamentoSublinha.PlanejamentoSublinha.SeqPvl,
        transbordoPlanejamentoSublinha.PlanejamentoSublinha.SeqPvs, transbordoPlanejamentoSublinha.PlanejamentoSublinha.Linha.LinhaID, transbordoPlanejamentoSublinha.PlanejamentoSublinha.Sublinha.SublinhaID).subscribe(data => {
          this.listarColetasPorPlanejamentoResponse.push(data);
        });
        this.pesoTotalColetado += transbordoPlanejamentoSublinha.PlanejamentoSublinha.PesoTotalColetas;
      }
    });
  }


  buscarPesoTicket(event: any, planejamentoTransbordo: PlanejamentoTransbordoDto) {
    var numeroTicket = event.target.value;

    this.ticketService.detalharTicket(planejamentoTransbordo.EmpresaID, planejamentoTransbordo.FilialID, numeroTicket, planejamentoTransbordo.Veiculo.Placa, planejamentoTransbordo.DataPrevistaSaida)
      .subscribe(data => {
        planejamentoTransbordo.PesoAferido = data.PesoAferido;
        this.pesoTotalAferido = data.PesoAferido;


        var integrarColetasPorTransbordoERPRequestExistente = this.integrarColetasPorTransbordoERPRequest
          .PlanejamentosLinhaTransbordo
          .find(x => x.EmpresaID == planejamentoTransbordo.EmpresaID
            && x.FilialID == planejamentoTransbordo.FilialID
            && x.SeqPvl == planejamentoTransbordo.SeqPvl
            && x.Linha.LinhaID == planejamentoTransbordo.Linha.LinhaID);

        if (integrarColetasPorTransbordoERPRequestExistente == undefined) {
          var planejamentoTransbordoDto: PlanejamentoTransbordoDto = new PlanejamentoTransbordoDto();
          planejamentoTransbordoDto.EmpresaID = planejamentoTransbordo.EmpresaID;
          planejamentoTransbordoDto.FilialID = planejamentoTransbordo.FilialID;
          planejamentoTransbordoDto.Linha.LinhaID = planejamentoTransbordo.Linha.LinhaID;
          planejamentoTransbordoDto.SeqPvl = planejamentoTransbordo.SeqPvl;
          planejamentoTransbordoDto.NumeroTicket = numeroTicket;
          planejamentoTransbordoDto.PesoAferido = data.PesoAferido;

          this.integrarColetasPorTransbordoERPRequest.PlanejamentosLinhaTransbordo.push(planejamentoTransbordoDto);
        } else {
          integrarColetasPorTransbordoERPRequestExistente.NumeroTicket = numeroTicket;
          integrarColetasPorTransbordoERPRequestExistente.PesoAferido = data.PesoAferido;
        }
      }, err => {
        console.log(err);
      });
  }

  salvarAfericaoPeso() {
    this.transbordoService.integrarColetasDeTransbordoERP(this.integrarColetasPorTransbordoERPRequest)
      .subscribe(() => {
        this.modalController.dismiss(true);
      }, err => {
        console.error(err);
      });
  }
}

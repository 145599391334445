import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Ticket } from '../classes/tickets/ticket';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private http: HttpClient) { 
  }

  detalharTicket(empresaID: number, filialID: number,  numeroTicket: number, placaVeiculo: string, dataPrevistaInicioPlanejamento: Date): Observable<Ticket>{

    const params = new HttpParams({
      fromObject: {
        empresaID,
        filialID,
        numeroTicket,
        placaVeiculo,
        dataPrevistaInicioPlanejamento: dataPrevistaInicioPlanejamento.toString()
      }
    });


    return this.http.get(`${environment.baseUrlCSPDotNet}/api/tickets`, {params: params }).pipe(
      map((result:Ticket)=>{
        return result;
      })
    )
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ListarQuantidadeProdutosColetadosPorPlanejamentoResponse, ProdutosColetadosDto } from 'src/app/classes/coletas/listar-quantidade-produtos-coletados-por-planejamento-response';
import { PlanejamentoAguardandoTransbordo } from 'src/app/classes/planejamentos/planejamento-aguardando-transbordo';
import { PlanejamentoLinhaDisponivelParaTransbordo } from 'src/app/classes/planejamentos/planejamento-linha-disponivel-para-transbordo';
import { ItensTransbordar, PlanejamentoLinha, PlanejamentoSublinha, RealizarPlanejamentoTransbordoRequest } from 'src/app/classes/transbordo/realizar-planejamento-transbordo-request';
import { ColetasService } from 'src/app/services/coletas/coletas.service';
import { PlanejamentoService } from 'src/app/services/planejamentos/planejamento.service';
import { TransbordoService } from 'src/app/services/transbordos/transbordo.service';
import { ExibirErroService } from 'src/app/services/validacoes/exibir-erro.service';

@Component({
  selector: 'app-transbordar',
  templateUrl: './transbordar.component.html',
  styleUrls: ['./transbordar.component.scss'],
})
export class TransbordarComponent implements OnInit {
  @Input() planejamento: PlanejamentoAguardandoTransbordo;
  declare listarQuantidadeProdutosColetadosPorPlanejamentoResponse: ListarQuantidadeProdutosColetadosPorPlanejamentoResponse;
  declare linhasDisponiveisParaTransbordo: Array<PlanejamentoLinhaDisponivelParaTransbordo>;
  declare realizarPlanejamentoTransbordoRequest: RealizarPlanejamentoTransbordoRequest;



  constructor(private formBuilder: FormBuilder,
    private exibirErroService: ExibirErroService,
    private coletaService: ColetasService,
    private planejamentoService: PlanejamentoService,
    private transbordoService: TransbordoService,
    private modalController: ModalController,) {




    this.listarQuantidadeProdutosColetadosPorPlanejamentoResponse = new ListarQuantidadeProdutosColetadosPorPlanejamentoResponse();
    this.linhasDisponiveisParaTransbordo = new Array<PlanejamentoLinhaDisponivelParaTransbordo>();
    this.realizarPlanejamentoTransbordoRequest = new RealizarPlanejamentoTransbordoRequest();
    this.realizarPlanejamentoTransbordoRequest.ItensTransbordar = new Array<ItensTransbordar>();
  }

  async ngOnInit() {


    this.exibirErroService.formGroup = this.formBuilder.group({
      linha: [null, Validators.required],
    });


    this.coletaService.listarQuantidadeProdutosColetados(this.planejamento.EmpresaID, this.planejamento.FilialID, this.planejamento.SeqPvl, this.planejamento.SeqPvs, this.planejamento.Linha.LinhaID, this.planejamento.Sublinha.SublinhaID).subscribe(data => {
      this.listarQuantidadeProdutosColetadosPorPlanejamentoResponse = data;

      for (let index = 0; index < this.listarQuantidadeProdutosColetadosPorPlanejamentoResponse.ProdutosColetados.length; index++) {
        this.exibirErroService.formGroup.addControl('quantidadeParaTransbordar' + index.toString(), new FormControl('', Validators.required));
      };
    });

    //buscar os itens das coletas


    //buscar as linhas que podem receber transbordo
    this.planejamentoService.listarPlanejamentosLinhasDisponiveisTransbordo().subscribe(data => {
      this.linhasDisponiveisParaTransbordo = data
    });
  }

  adicionarQuantidadeParaTransbordo(event, produtoColetado: ProdutosColetadosDto) {
    var valorDigitado = event.target.value;
    if (valorDigitado > produtoColetado.QuantidadeATransbordar) {
      alert(`Quantidade máxima a ser transbordada para este produto é de ${produtoColetado.QuantidadeATransbordar} kg`)
      event.target.value = '';
      return;
    }

    var itemJaLancado = this.realizarPlanejamentoTransbordoRequest.ItensTransbordar.find(x => x.ProdutoID === produtoColetado.Produto.ProdutoID);

    if (itemJaLancado == null) {
      var itensTransbordados = new ItensTransbordar();
      itensTransbordados.ProdutoID = produtoColetado.Produto.ProdutoID;
      itensTransbordados.Quantidade = valorDigitado;

      this.realizarPlanejamentoTransbordoRequest.ItensTransbordar.push(itensTransbordados);
    } else {
      itemJaLancado.Quantidade = event.target.value;
    }
  }


  atribuirPlanejamentoLinhaParaTransbordo(event) {
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoLinha = new PlanejamentoLinha();
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoLinha.EmpresaID = event.detail.value.EmpresaID;
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoLinha.FilialID = event.detail.value.FilialID;
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoLinha.SeqPvl = event.detail.value.SeqPvl;
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoLinha.LinhaID = event.detail.value.Linha.LinhaID;
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoLinha.PlacaVeiculo = event.detail.value.Veiculo.Placa;
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoLinha.MotoristaID = event.detail.value.Motorista.MotoristaID;
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoLinha.NomeMotorista = event.detail.value.Motorista.Nome;
  }


  async salvarTransbordo() {
    if (this.exibirErroService.validar() == false)
      return;

    this.realizarPlanejamentoTransbordoRequest.PlanejamentoSublinha = new PlanejamentoSublinha();
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoSublinha.EmpresaID = this.planejamento.EmpresaID;
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoSublinha.FilialID = this.planejamento.FilialID;
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoSublinha.SeqPvl = this.planejamento.SeqPvl;
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoSublinha.SeqPvs = this.planejamento.SeqPvs;
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoSublinha.LinhaID = this.planejamento.Linha.LinhaID;
    this.realizarPlanejamentoTransbordoRequest.PlanejamentoSublinha.SublinhaID = this.planejamento.Sublinha.SublinhaID;


    this.transbordoService.realizarPlanejamentoTransbordo(this.realizarPlanejamentoTransbordoRequest).subscribe(() => {
      this.modalController.dismiss();
    }, err => {
      console.error(err);
    })
  }
}
